// Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles/Footer.css';
import logo from "./images/footer-logo-new.png"

const Footer = () => {
  return (
    <footer className="footer text-white">
    <div className="container">
      <div className="footer-content d-flex justify-content-between align-items-center">
        <div className="logo">
          <img src={logo} alt="Logo" className="footer-logo" />
        </div>
        <Container className="py-2">
          <Row>
            <Col>
              <a href="/privacyPolicy" className="text-white">Privacy Policy</a> 
            </Col>
            <Col>
              <a href="/t&c" className="text-white">Terms & Conditions</a> 
            </Col>
          </Row>
        </Container>
        <div className="copyright">
          &copy; {new Date().getFullYear()} CouponBasket. All rights reserved.
        </div>
      </div>
    </div>
    </footer>
  );
}

export default Footer;
