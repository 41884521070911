import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../provider/authProvider";

const PrivateRoute = () => {
  const { token } = useAuth();
  const location = useLocation();

  return token ? <Outlet /> : <Navigate to="/Login" state={{ from: location }} replace />;
};

export default PrivateRoute;