import React, { useState, useEffect, useCallback } from 'react';
import "./styles/App.css";
import axios from 'axios';
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Outlet } from "react-router";
import useLocation from './utils/useLocation';
import MerchantMenuPage from "./pages/MerchantMenuPage";
import Homepage from "./pages/Homepage";
import CouponDetailsPage from "./pages/CouponDetailsPage";
import SignUpPage from "./pages/SignUpPage";
import LoginPage from "./pages/LoginPage";
import CartPage from "./pages/CartPage";
import WalletPage from "./pages/WalletPage";
import InStoreCouponDetailsPage from "./pages/InStoreCouponDetailsPage";
import ClaimsDetailsPage from "./pages/ClaimsDetailsPage";
import TopDealsPage from "./pages/TopDealsPage"
import ViewAllCouponsPage from './pages/ViewAllCouponsPage';
import ViewAllMerchantsPage from './pages/ViewAllMerchantsPage';
import SearchResultsPage from './pages/SearchResultsPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import { useAuth } from "./provider/authProvider";
import PrivateRoute from "./utils/PrivateRoute";
import { getMenuUrl, getCouponUrl } from "./utils/HelperFunctions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  // URL based on run environment
  const apiUrl = process.env.REACT_APP_apiUrl;

  const { token } = useAuth();
  const [searchText, setSearchText] = useState("");
  const [coupons, setCoupons] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const updateSearchText = (str) => {
    setSearchText(str);
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [couponsResponse, merchantsResponse] = await Promise.all([
        axios.get(`${apiUrl}/GetNearByCoupons`, {
          params: { email: token, maxDistance: 20 },
        }),
        axios.get(`${apiUrl}/GetNearByMerchants`, {
          params: { email: token, maxDistance: 20 },
        }),
      ]);
      setCoupons(couponsResponse.data);
      setMerchants(merchantsResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [token, apiUrl]);

  useEffect(() => {
      fetchData(); // Initial call to fetch data
  }, [token, apiUrl]);

  // Pass fetchData as the callback, so that fetchData is only called when users location is updated
  const { handleGetLocation, errorMessage } = useLocation(apiUrl, token, fetchData); 

  useEffect(() => {
    if (token) {
      handleGetLocation(); // Initial call to get location
      
  
      const intervalId = setInterval(handleGetLocation, 60000); // Call handleGetLocation every 60 seconds
  
      return () => clearInterval(intervalId); // Clean up on unmount
    }
  }, [token, handleGetLocation, fetchData]);
 

  return (
    <div className="App" id="outer-container">
      <Router>
        <Routes>
          <Route path="/Signup" element={<SignUpPage />} />
          <Route path="/Login" element={<LoginPage />} />
          <Route path="/InStoreCoupon/:merchantID" element={<InStoreCouponDetailsPage />} />
          <Route
            element={
              <>
                <Navbar searchText={searchText} updateSearchText={updateSearchText} token={token}/>
                <Outlet />
              </>
            }
          >
            <Route
              path="/"
              element={
                <Homepage
                  coupons={coupons}
                  merchants={merchants}
                  isLoading={isLoading}
                  isError={isError}
                />
              }
            />

            {/* Dynamically generated routes for merchants and coupons */}
            {merchants.map((merchant) => (
              <Route
                key={merchant.merchantID}
                path={`/${getMenuUrl(merchant.merchantID)}`}
                element={<MerchantMenuPage merchant={merchant} coupons={coupons} />}
              />
            ))}
            {coupons.map((coupon) => (
              <Route
                key={coupon.couponID}
                path={`/${getCouponUrl(coupon.couponID)}`}
                element={<CouponDetailsPage coupon={coupon} />}
              />
            ))}
            <Route path="/TopDeals" element={<TopDealsPage/>} />
            <Route path="/view-all-coupons" element={<ViewAllCouponsPage />} />
            <Route path="/view-all-merchants" element={<ViewAllMerchantsPage />} />
            <Route path="/search" element={<SearchResultsPage />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy isPopup={false} />} />
            <Route path="/t&c" element={<TermsAndConditions isPopup={false} />} />

            {/* All routes that require authentication are wrapped in PrivateRoute */}
            <Route element={<PrivateRoute />}>
              <Route path="/claim/" element={<ClaimsDetailsPage />} />
              <Route path="/Wallet" element={<WalletPage />} />
              {/*<Route path="/Cart" element={<CartPage coupons={coupons} />} />  implement later*/}
            </Route>
          </Route>
        </Routes>
      </Router>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
}

export default App;
