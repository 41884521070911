import React from 'react';
import {Container, Row, Col, Modal, Button } from 'react-bootstrap';
import Footer from './Footer';
import '../styles/PrivacyPolicy.css'; // Include your CSS file for styling

const TermsAndConditions = ({ isPopup, handleClose, handleAccept }) => {

  const renderButtons = () => (
    <Modal.Footer className='modal-buttons'>
      <Button variant="secondary" onClick={handleClose}>
        Decline
      </Button>
      <Button variant="primary" onClick={handleAccept}>
        Accept
      </Button>
    </Modal.Footer>
  );

  return (
    <div className={isPopup ? '' : 'banner-slider'}>
      <div className={`privacy-policy-container ${isPopup ? 'popup' : 'full-page'}`}>
        <Container fluid className={`privacy-policy-header ${isPopup ? 'popup' : 'full-page'}`}>
          <h1 className="mb-0 mt-0">Terms & Conditions</h1>
          <small className="mt-0">Effective Date : September 15, 2024</small>
        </Container>
        <div className={`privacy-policy-content ${isPopup ? 'popup' : ''}`}>
          <p className="mt-0">
            <b>1. Introduction</b><br/>
            Welcome to CouponBaskets! These terms and conditions outline the rules and regulations for the use of our web application. 
            By accessing this website, we assume you accept these terms and conditions. 
            Do not continue to use CouponBaskets if you do not agree to all of the terms and conditions stated on this page.
          </p>
          <p>
            <b>2. License</b><br/>
            Unless otherwise stated, CouponBaskets and/or its licensors own the intellectual property rights for all material on CouponBaskets. 
            All intellectual property rights are reserved. You may access this from CouponBaskets for your own personal use subjected to restrictions set in 
            these terms and conditions.
          </p>
          <p>
            <b>3. User Accounts</b><br/>
            If you create an account on CouponBaskets, you are responsible for maintaining the security of your account and are fully responsible for 
            all activities that occur under the account. We may require you to change your username if we believe it is inappropriate or offensive.
          </p>
          <p>
            <b>4. Use of Coupons</b><br/>
            Coupons offered on CouponBaskets are for personal use only. Any commercial use, resale, or redistribution of coupons is strictly prohibited. 
            Coupons may have specific terms and conditions, expiration dates, and usage limits set by the merchants.
          </p>
          <p> 
            <b>5. Notifications and Marketing Emails</b><br/>
            By creating an account on CouponBaskets, you agree to receive notifications about expiring coupons, new coupons issued by merchants, and marketing emails. 
            You can manage your notification preferences in your account settings.
          </p>
          <p>
            <b>6. Limitation of Liability</b><br/>
            CouponBaskets will not be liable for any damages arising out of or in connection with the use of our web application. This includes, without limitation, 
            direct, indirect, incidental, or consequential damages.
          </p>
          <p>
            <b>7. Modification of Terms</b><br/>
            CouponBaskets reserves the right to revise these terms and conditions at any time without prior notice. By using this website, you are expected 
            to review these terms regularly.
          </p>
          <p>
            <b>8. Governing Law</b><br/>
            These terms and conditions are governed by and construed in accordance with the laws of Ontario, Canada. Any disputes relating to these terms and 
            conditions will be subject to the exclusive jurisdiction of the courts of Ontario.
          </p>
          <p>
            <b>9. Compliance with PIPEDA</b><br/>
            CouponBaskets complies with the Personal Information Protection and Electronic Documents Act (PIPEDA), which governs how private-sector organizations 
            collect, use, and disclose personal information in the course of commercial activities across Canada. We adhere to PIPEDA's 10 fair information principles 
            to ensure the protection of your personal information.
          </p>
        </div>
        {isPopup ? (
          renderButtons()
        ) : (
          <>
            <Container className="mb-2">
              <Row>
                <a className="mx-auto" href="/"> 
                  <Button style={{ background: "#e80d0d", color: "#ffffff", borderColor: "#e80d0d" }}> 
                    Back to Home 
                  </Button> 
                </a>
              </Row>
            </Container>
            <Footer />
          </>
        )}
      </div>
    </div>
  );
};

export default TermsAndConditions;
