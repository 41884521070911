import React from 'react';
import {Container, Row, Modal, Button } from 'react-bootstrap';
import Footer from '../components/Footer';
import '../styles/PrivacyPolicy.css'; // Include your CSS file for styling

const PrivacyPolicy = ({ isPopup, handleClose, handleAccept }) => {
  const renderButtons = () => (
    <Modal.Footer className='modal-buttons'>
      <Button variant="secondary" onClick={handleClose}>
        Decline
      </Button>
      <Button variant="primary" onClick={handleAccept}>
        Accept
      </Button>
    </Modal.Footer>
  );

  return (
    <div className={isPopup ? '' : 'banner-slider'}>
      <div className={`privacy-policy-container ${isPopup ? 'popup' : 'full-page'}`}>
        <Container fluid className={`privacy-policy-header ${isPopup ? 'popup' : 'full-page'}`}>
          <h1 className="mb-0 mt-0">Privacy Policy</h1>
          <small className="mt-0">Effective Date : September 15, 2024</small>
        </Container>
        <div className={`privacy-policy-content ${isPopup ? 'popup' : ''}`}>
          <p className="mt-0">
            Coupon Basket aims to enhance users’ shopping experience by providing easy access to discounted products or services through collected coupons. 
            This Privacy Policy explains how we handle user information.
          </p>
          <p>
            <b>PLEASE READ THIS AGREEMENT CAREFULLY.</b><br/>
            BY BROWSING, ACCESSING OR USING THIS WEBSITE OR MOBILE APPLICATION OR BY USING ANY FACILITIES OR SERVICES MADE AVAILABLE THROUGH OR ON IT, 
            YOU ARE AGREEING TO THE TERMS OF USE THAT APPEAR BELOW (ALL OF WHICH ARE CALLED THE “AGREEMENT”). THIS AGREEMENT IS MADE BETWEEN YOU AND US. 
            USE OF THE SERVICES AND THE WEBSITE OR MOBILE APPLICATION AND ANY PURCHASE MADE THERETO ARE EACH SUBJECT TO THE TERMS OF USE SET OUT IN THIS AGREEMENT<br/>
            <br/>
            By creating an account on CouponBaskets, you agree to receive notifications about expiring coupons, new coupons issued by merchants, and marketing emails. 
            You can manage your notification preferences in your account settings<br/>
          </p>
          <p>
            What is “Personal Information”<br/>
            References to “Personal Information” in this privacy policy means any information about an identifiable individual or that allows an individual to be identified. 
            Information that is aggregated and/or de-identified and cannot be associated with an identifiable individual is not considered to be Personal Information.<br/>
          </p>
          <p>
          <b>Types of Data Collected</b><br/>
            We collect the following categories of personal information:<br/>
            <br/>
            Identifiers:<br/>
            Name<br/>
            Email<br/>
            Telephone numbers<br/>
            Demographic Information:<br/>
            Age<br/>
            Gender<br/>
            <br/>
            Commercial Information:<br/>
            Purchasing tendencies<br/>
            Order history (e.g., redeemed vouchers)<br/>
            Areas of interest<br/>
            <br/>
            Location Information:<br/>
            State/province, city, or neighborhood (general)<br/>
            More specific location information (if user agrees via GPS functionality)<br/>
            <br/>
            Financial Information:<br/>
            Payment card details for voucher purchases<br/>
            Internet and Network Activity Information:<br/>
            <br/>
            Browsing behavior<br/>
            Interactions with websites and advertisements (e.g., cookies, pixel tags)<br/>
            <br/>
            Inferences Regarding Preferences:<br/>
            Assessment of user interests in specific products or services<br/>
            How We Collect Personal Information<br/>
            We collect personal information from users in the following ways:<br/>
            <br/>
            Directly from Users:<br/>
            When users interact with Coupon Basket (e.g., making purchases, registering, using the app).<br/>
            Voluntarily provided information.<br/>
          </p>
          <p>
            <b>User Rights</b><br/>
            Users have the right to:<br/>
            <br/>
            Deny location permissions<br/>
            Clear cache to delete previous web activities<br/>
            Updates<br/>
            Users will be notified of any policy changes via email.<br/>
            <br/>
            We think that you benefit from a more personalized experience when we know more about you and what you like. 
            However, you can limit the information you provide toCoupon Basket, and you can limit the communications thatCoupon Basket sends to you. In particular:<br/>
            <br/>
            Commercial Emails: You may choose not to receive commercial e-mails from us by following the instructions contained in any of the commercial e-mails we send or by logging into your account 
            and adjusting your email preferences. Please note that even if you unsubscribe from commercial email messages, we may still email you non-commercial emails related to your account and your 
            transactions on the Service. You may update your subscription preferences at any time.<br/>
            Cookies and Other Technologies: You may manage how your browser handles cookies by adjusting its privacy and security settings. Browsers are different, so refer to instructions related to your 
            browser to learn about cookie-related and other privacy and security settings that may be available. We use internet and network activity information and device data to directly serve 
            you interest-based ads on third party websites and mobile applications.<br/>
            Device Data: You may manage how your mobile device and mobile browser share certain device data withCoupon Basket, as well as how your mobile browser handles cookies by adjusting the 
            privacy and security settings on your mobile device. Please refer to the instructions provided by your mobile service provider or the manufacturer of your device to learn 
            how to adjust your settings.<br/>
            When you first visit or use the Service we may request permission to collect and use your device’s precise geolocation. You can opt not to permit the collection of this information, 
            or permit it only when using the mobile app, but it may limit certain functions or features of the Service. You can control how and whether we collect your precise geolocation information 
            through your device’s settings.<br/>
            <br/>
            Emails from Business Partners: If you wish to opt-out of receiving offers directly from our business partners, you can follow the opt-out instructions in the emails they send you or you 
            can log into your account and adjust your email preferences.
          </p>
          <p>
            <b>Use of Data</b><br/>
            We use this data to Improve user experience in collecting and discovering relevant coupons, andFacilitate coupon usage wherever users intend to shop.
          </p>
          <p>
            <b>Contact Information</b><br/>
            Pactfolio Inc<br/>
            15 Kells Avenue Scarborough Ontario m1k4w6<br/>
            <br/>
            <b>Updates</b><br/>
            All updates will be communicated via email.
          </p>
        </div>
        {isPopup ? (
          renderButtons()
        ) : (
          <>
            <Container className="mb-2">
              <Row>
                <a className="mx-auto" href="/"> 
                  <Button style={{ background: "#e80d0d", color: "#ffffff", borderColor: "#e80d0d" }}> 
                    Back to Home 
                  </Button> 
                </a>
              </Row>
            </Container>
            <Footer />
          </>
        )}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
