import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Row, Col, Container, Collapse, Button, ListGroup, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from "../provider/authProvider";
import "../styles/CoupenDetails.css";
import HowToClaim from '../components/HowToClaim';
import Footer from "../components/Footer";
import location from "../components/images/location.svg";


const ClaimsDetailsPage = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_apiUrl;

  const { state } = useLocation();
  const { token } = useAuth(); // to get email
  const email = token;

  const [coupon, setCoupon] = useState(state.coupon || {});
  const [imageUrl] = useState(state.imageUrl || {}); // Initial image as fallback
  const [isClaimed, setIsClaimed] = useState(coupon.isClaimedFlag);
  const [claimID, setClaimID] = useState(coupon.claimID);
  const [claimDetails, setClaimDetails] = useState({});
  const [alertMessage, setAlertMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);


  useEffect(() => {
    if (claimID) {
      const url = `${apiUrl}/GetClaimDetails?claimID=${encodeURIComponent(claimID)}`;

      axios.get(url)
        .then(response => {
          setClaimDetails(response.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          if (error.response) {
            if (error.response.status === 404) {
              setAlertMessage({ type: 'danger', text: 'Claim details not found.' });
            } else if (error.response.status === 500) {
              setAlertMessage({ type: 'danger', text: 'Unexpected error while fetching claim details.' });
            } else {
              setAlertMessage({ type: 'danger', text: 'Network response was not ok.' });
            }
          } else {
            setAlertMessage({ type: 'danger', text: error.message });
          }
        });
    }
  }, [claimID]);

  const handleClaim = async () => {
    if (!coupon) {
      console.error('No coupon to claim');
      setAlertMessage({ type: 'danger', text: 'No coupon to claim' });
      return;
    }

    try {
      const url = `${apiUrl}/ClaimCoupon`;

      const response = await axios.post(url, null, {
        params: {
          id: coupon.id,
          email: email
        },
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
        }
      });

      const responseData = response.data;

      if (responseData.success) {
        setAlertMessage({ type: 'success', text: responseData.message });
        setIsClaimed(true);
        setClaimID(responseData.data);

        // to keep state after refresh
        let updatedCoupon = {
          ...coupon,  // keep old data
          isClaimedFlag: true, claimID: responseData.data  // update the values of specific keys
        };
        setCoupon(updatedCoupon);
        navigate(null, { replace: true, state: { coupon: updatedCoupon } });

      } else {
        if (responseData.message === 'Please sign up.') {
          navigate('/Signup');
        } else {
          setAlertMessage({ type: 'danger', text: responseData.message });
        }
      }
    } catch (error) {
      console.error('Error: Coupon could not be claimed:', error);
      if (error.response) {
        if (error.response.status === 404) {
          setAlertMessage({ type: 'danger', text: 'Customer details not found or email mismatch.' });
        } else if (error.response.status === 403) {
          setAlertMessage({ type: 'danger', text: 'Please click claim once you are at the store.' });
        } else if (error.response.status === 409) {
          setAlertMessage({ type: 'danger', text: 'Claim wallet updation Error: coupon could not be claimed.' });
        } else if (error.response.status === 500) {
          setAlertMessage({ type: 'danger', text: 'Unexpected error while fetching wallet details.' });
        } else {
          setAlertMessage({ type: 'danger', text: 'Network response was not ok.' });
        }
      } else {
        setAlertMessage({ type: 'danger', text: error.message });
      }
    } finally {
      setShowModal(true);
    }
  };

  function CollapsibleHowToClaim() {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button className="w-100 text-left dropdown-toggle border-0 shadow-none font-italic"
                style={{background: '#00000008', color: '#000000', fontSize: '12px'}}
                onClick={() => setOpen(!open)}
                aria-controls="collapse-how-to-claim"
                aria-expanded={open}
            >
                {open ? 'Hide How to Claim' : 'How to Claim'}
            </Button>
            <Collapse in={open}>
                <div id="collapse-how-to-claim" className="card-text mt-0 mb-0">
                    <HowToClaim />
                </div>
            </Collapse>
        </>
    );
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setAlertMessage(null);
  };

  return (
    <div>
      <Container className="mx-auto mb-3 coupen-details-wrap">
        <div className="coupen-details-inner-div mx-auto">
          <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Body className="text-center">
              <p style={{ color: alertMessage?.type === 'success' ? 'green' : 'red' }}>
                {alertMessage?.text}
              </p>
            </Modal.Body>
            <Modal.Footer className="justify-content-center border-0">
              <Button variant="primary" onClick={handleCloseModal}>
                OK
              </Button>
            </Modal.Footer>
          </Modal>
          <h1 className="c-detail-title">   {coupon.merchantName}</h1>
          <Card className="coupon-menu-item" style={{ borderWidth: "0.5px" }}>
            <Col className="md-4 h-50 px-0 py-0">
              <Card.Img
                className="w-100 coupen-image-name"
                variant="top"
                src={imageUrl}
              />
            </Col>
            <Card.Body className="pl-0 pr-0">
              <Row className="ml-1">
                <Col md={11}>
                  <Card.Title
                    className="c-detail-title mb-0"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    {coupon.couponName}
                  </Card.Title>
                </Col>
              </Row>
              <Row className="mx-2 py-2 my-2 rounded-lg border" style={{ background: '#00000008' }}>
                <Col md={11}>
                  {isClaimed === false &&
                    <p className="my-0">
                      Ready to be claimed, please carefully read the description before claiming.
                    </p>
                  }
                  {isClaimed === true &&
                    <p className="my-0">
                      <i>Claimed on {claimDetails.claimedOn}, show this at the store before <b>{claimDetails.expiresBy}</b></i>
                    </p>
                  }
                </Col>
              </Row>
              <Row className="coupon-description ml-1">
                <Col md={11}>
                  <Card.Text style={{ fontSize: "14px" }}>
                    {coupon.couponDescription}
                  </Card.Text>
                </Col>
              </Row>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <Row className="ml-1 my-auto">
                <Col className="mt-1" md={12}>

                  <p className="coupen-rate-now" style={{ color: "#e80d0d" }}>
                  NOW ${coupon.offerPrice}
                    <sup className="previous-prize insto-p">
                    In-Store <span className="in-sp-t">${coupon.inStorePrice}</span>
                    </sup>
                  </p>
                  <p className="card-text mt-2 mb-3">
                    <small
                      className="text you-save coupen-detail-save"
                      style={{ color: "#007aff", fontWeight: "600"  }}
                    >
                       You save ${coupon.inStorePrice - coupon.offerPrice}
                    </small>
                    <small
                      className="text other-tags coupen-detail-save"
                      style={{ fontWeight: "600"  }}
                    >
                       {coupon.couponCategory}
                    </small>
                  </p>
                  <div className="location-expire-wrap">
                    <p className="expiry-text">Valid till: {coupon.expiresBy}</p>
                  </div>
                    
                 
                </Col>
                <div></div>
                {/* <Col className="d-flex justify-content-center align-items-center">
             
                </Col> */}
              </Row>
            </ListGroup>
            <ListGroup className="list-group-flush">
              <Row className="px-0 mx-0">
                    <CollapsibleHowToClaim />
              </Row>
            </ListGroup>
            <Card.Footer className="bg-white" style={{ fontSize: "14px" }}>
              <Row className="mx-0">
              <Col className="d-flex align-items-center px-0 font-weight-bold">
                {coupon.distanceToMerchant} away
              </Col>
              <Col xs={'auto'} className="d-flex justify-content-center align-items-center">
                {isClaimed === false &&
                  <Button
                    className="border-0 px-3"
                    style={{ borderRadius: '16px', backgroundColor: '#e80d0d', color: '#ffffff' }}
                    onClick={handleClaim}>
                    Claim Now
                  </Button>
                }
                {isClaimed === true &&
                  <Button
                    className="border-0 px-3"
                    style={{ borderRadius: '16px', backgroundColor: '#808080', color: '#ffffff' }}
                    disabled>
                    Claimed ✓
                  </Button>
                }
              </Col>
              </Row>
            </Card.Footer>
          </Card>
        </div>
      </Container>
      {/*<div>
      <Container className="mx-auto mb-3 coupen-details-wrap">
        <Col className="col col-md-8 mx-auto px-0 px-md-auto">
          <Card className="coupon-details-card" style={{ background: '#FAF9F6' }}>
            <Col className="h-50 px-0 py-0 bg-black">
              <Card.Img className="w-100" variant="top" src={imageUrl} />
              <Card.ImgOverlay className="d-flex justify-content-center align-items-end">
                <div className="rounded-pill mt-0 mb-1 w-50 text-center float-right font-weight-bold"
                  style={{ background: '#ffffff', color: '#000000', borderColor: '#ffffff', fontSize: '18px' }}>{coupon.merchantName}
                </div>
              </Card.ImgOverlay>
            </Col>
            <Card.Body className="pl-0 pr-0 pt-1 pb-0 my-auto">
              <Row className="py-1">
                <Col md={11}>
                  <Card.Title className="my-auto text-center font-weight-bold" style={{ fontSize: "18px" }}>{coupon.couponName}</Card.Title>
                </Col>
              </Row>
              <Row className="mx-2 py-2 my-2 rounded-lg border" style={{ background: '#00000008' }}>
                <Col md={11}>
                  {isClaimed === false &&
                    <p>
                      Ready to be claimed!
                    </p>
                  }
                  {isClaimed === true &&
                    <p>
                      <i>Coupon was claimed on {claimDetails.claimedOn} and is valid till <b>{claimDetails.expiresBy}</b></i>
                    </p>
                  }
                </Col>
              </Row>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <Row className="px-0 mx-0">
                    <CollapsibleHowToClaim />
              </Row>
            </ListGroup>
            <Card.Footer style={{ fontSize: '14px' }}>
              <Row className="mx-0">
                <Col className="d-flex align-items-center px-0">
                *you save {coupon.inStorePrice - coupon.offerPrice}$ through this coupon
                </Col>
                <Col xs={'auto'} className="d-flex justify-content-center align-items-center">
                  {isClaimed === false &&
                    <Button
                      className="border-0 px-3"
                      style={{ borderRadius: '16px', backgroundColor: '#e80d0d', color: '#ffffff' }}
                      onClick={handleClaim}>
                      Claim Now
                    </Button>
                  }
                  {isClaimed === true &&
                    <Button
                      className="border-0 px-3"
                      style={{ borderRadius: '16px', backgroundColor: '#808080', color: '#ffffff' }}
                      disabled>
                      Claimed ✓
                    </Button>
                  }
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      </Container>
    </div>*/}
    </div>
    

  );
};

export default ClaimsDetailsPage;
