import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getCouponUrl } from "../utils/HelperFunctions";
import { useParams, useNavigate } from 'react-router-dom';

import dummyimg from "../components/images/default/couponFallback.png"
import location from "../components/images/location.svg"
import restaurent from "../components/images/rest-icon.svg"

import '../styles/WalletCard.css'

function WalletCard({ coupon }) {
    // URL based on run environment
    const apiUrl = process.env.REACT_APP_apiUrl;
    
    const navigate = useNavigate();
    const [imageUrl, setImageUrl] = useState(dummyimg); // Initial image as fallback
    const [isLoading, setIsLoading] = useState(true);

    /**
     * Fetches the presigned URL for the image associated with the coupon.
     */
    const fetchImage = async (objectKey) => {
        try {
        const response = await axios.get(
            `${apiUrl}/GeneratePresignedUrl`,
            {
            params: { objectKey },
            }
        );
        setImageUrl(response.data); // Set the fetched image URL
        } catch (error) {
        console.error("Error fetching image:", error);
        setImageUrl(dummyimg); // Fallback in case of an error
        } finally {
        setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchImage("CouponImages/"+coupon.merchantID+"/"+coupon.imgName); // Trigger the image fetch on component mount
    }, [coupon.couponID]);

    const navigateToClaims = () =>{  
        navigate('/claim', {state: {coupon,imageUrl}});
      }

     return (
        <div  className="wallet-card-item">
            <div className="card " onClick={navigateToClaims}>
                <div className="">
                    <div className="">
                        <img src={imageUrl} className="wallet_image" height="144px" alt=""/>
                    </div>
                    <div className="">
                        <div className="card-inner">
                        <div className="restaurant-name">
                            <span><img src={restaurent} alt="restaurant" className="rest-icon-img-new"/> </span>
                            {coupon.merchantName}</div>
                    
                            <h5 className="card-title item-name">{coupon.couponName}</h5>
                            {/* <p className="card-text mt-0"><small className="text-muted ">*In-Store {coupon.instorePrice}</small></p>
                            <p className="card-text mt-1 mb-0 font-weight-bold" style={{ color: "#e80d0d" }}>NOW {coupon.now}</p> */}

                            <p className="wallet-rate-now" style={{ color: "#e80d0d" }}>
                                ${coupon.offerPrice}
                                <sup className="previous-prize insto-p">
                                    <span className="in-sp-t">${coupon.inStorePrice}</span>
                                </sup>
                            </p>
                            {/*<p className="previous-prize new-previous-prize">
                                In-Store <span className="c-instore-p"> ${coupon.inStorePrice}</span>
                            </p>*/}
                            <div className="coupon-tags">
                                <p className="you-save"><small className="text" style={{ color: "#007aff" }}>Save ${coupon.inStorePrice - coupon.offerPrice}</small></p>
                                <p className="other-tags"><small className="text">{coupon.couponCategory}</small></p>
                            </div>
                            <div className="d-flex expiry-main">
                                <p className="expiry-text">Valid till: {coupon.expiresBy}</p>
                            </div>
                            <div className="location"><span><img src={location} alt="location" /> </span>{coupon.distanceToMerchant}</div>
                            {coupon.isClaimedFlag === false &&
                                <Button className="claim" style={{ background: "#e80d0d", color: "#ffffff", borderColor: "#e80d0d" }}
                                        >
                                        Claim
                                </Button>   
                            }
                            {coupon.isClaimedFlag === true &&
                                <Button className="claim" style={{ left: "30%", background: "#808080", color: "#ffffff", borderColor: "#808080" }}
                                        >
                                        Details
                                </Button>
                            }        
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
}

export default WalletCard;
